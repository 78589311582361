class ScrollTo {
  static start() {
    let app = new ScrollTo()
    return app
  }

  constructor() {
    this.setupEvents()
  }

  setupEvents() {
    $(document).on("click", "[data-scroll-to]", function(event) {
      event.preventDefault()
      let element = $(event.target)

      let anchor = element.data("scroll-to")
      let target = $("#" + anchor)
      let scrollPosition = target.offset().top
      let animationDuration = 500
      $("html, body").animate({ scrollTop: scrollPosition }, animationDuration, () => {
        location.hash = "#" + anchor
      })
    })
  }
}
export default ScrollTo
