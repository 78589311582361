// Global catch all for ajax/ujs errors
class ErrorHandling {
  static start() {
    $(document).bind("ajaxError", function(xhr, response) {
      if(response.responseText.indexOf("App.ErrorHandling.redirectToErrorPage", 0) == 0) {
        eval(response.responseText)
      }
    })
  }

  static redirectToErrorPage(path) {
    location.href = path
  }
}

export default ErrorHandling
