class VideoSearch {
  static start() {
    $(document).ajaxSuccess(() => {
      this.startImmediate()
    })

    return this
  }

  static startImmediate() {
    $("[data-video-search='true']").each(function() {
      if($(this).attr("data-video-search-already-initialized")) {
        return
      }

      $(this).attr("data-video-search-already-initialized", true)
      new VideoSearch($(this))
    })

    return this
  }

  constructor(element) {
    this._element = element
    this._timer = null
    this._triggerTimer = null
    this._valueNotToTriggerFor = this.getElementValue()
    this._lastKnownValue = this._valueNotToTriggerFor

    let self = this

    this.$element.on("focus", function() {
      self.onFocus()
      return false
    })

    this.$element.on("blur", function() {
      self.onBlur()
      return false
    })
  }

  get $element() { return this._element }
  get $formElement() {
    if(this._formElement === undefined) {
      this._formElement = this.$element.closest("FORM")
    }
    return this._formElement
  }

  onFocus() {
    this.startTimer()
  }

  onBlur() {
    this.stopTriggerTimer()
    this.stopTimer()
    this.triggerIfNeeded()
  }

  startTimer() {
    let self = this
    if(this._timer) {
      this.stopTimer()
    }
    this._timer = setInterval(function() {
      self.onTimer()
    }, 50)
  }

  stopTimer() {
    if(!this._timer) { return }
    clearInterval(this._timer)
    this._timer = null
  }

  onTimer() {
    if(this.getElementValue() !== this._lastKnownValue) {
      this._lastKnownValue = this.getElementValue()
      this.stopTriggerTimer()
      this.startTriggerTimer()
    }
  }

  triggerTimeout() {
    if(this.elementIsSelectTag()) {
      return 50
    }
    return 1000
  }

  startTriggerTimer() {
    let self = this
    if(this._triggerTimer) {
      this.stopTriggerTimer()
    }
    this._triggerTimer = setTimeout(function() {
      self.onTriggerTimer()
    }, this.triggerTimeout())
  }

  stopTriggerTimer() {
    if(!this._triggerTimer) { return }
    clearTimeout(this._triggerTimer)
    this._triggerTimer = null
  }

  onTriggerTimer() {
    this.triggerIfNeeded()
  }

  triggerIfNeeded() {
    this.stopTriggerTimer()
    if(this.getElementValue() === this._valueNotToTriggerFor) {
      return
    }
    this._valueNotToTriggerFor = this.getElementValue()
    this._lastKnownValue = this._valueNotToTriggerFor
    this.submitForm()
  }

  elementIsSelectTag() {
    return this.$element[0].tagName === "SELECT"
  }

  elementIsInputTag() {
    return this.$element[0].tagName === "INPUT"
  }

  getElementValue() {
    return this.$element.val()
  }

  submitForm() {
    this.$formElement.submit()
  }
}

export default VideoSearch
