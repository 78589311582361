class LoginForm {
  static start() {
    let app
    $(document).on("turbolinks:load", (event) => {
      // only setup events on first visit
      if(!event.originalEvent.data.timing.visitStart) {
        app = this.startImmediate()
      }
    })
    return app
  }

  static startImmediate() {
    let app = new LoginForm("form[data-login-form='true']:first")
    return app
  }

  constructor(formElementPath) {
    this.formElementPath = formElementPath

    this.autoFocusBestField()
    this.update()

    $(document).on("change", "input:radio[name='do_you_have_a_password']", () => {
      this.update()
    })
  }

  get $formElement() {
    return $(this.formElementPath)
  }

  get $loginActionsElement() {
    return this.$formElement.find(".login-actions").first()
  }

  get $registerActionsElement() {
    return this.$formElement.find(".register-actions").first()
  }

  get $emailAddressField() {
    return this.$formElement.find("input[name='email_address']").first()
  }

  get $passwordField() {
    return this.$formElement.find("input[name='password']").first()
  }

  autoFocusBestField() {
    if(!this.focusFirstNonEmptyField()) {
      this.$emailAddressField.trigger("focus")
    }
  }

  focusFirstNonEmptyField() {
    var focusedField = undefined
    this.$formElement.find("input[type='text'], input[type='password'], textarea, select").each(function() {
      var val = $(this).val()
      if(val == undefined || val == "") {
        if(!$(this).is(":disabled")) {
          $(this).trigger("focus")
          focusedField = $(this)
          return false
        }
      }
    })
    return focusedField != undefined ? true : false
  }

  update() {
    if(this.userHasPassword()) {
      this.$loginActionsElement.show()
      this.$registerActionsElement.hide()
      this.$passwordField.prop("disabled", false)
      this.autoFocusBestField()
    } else {
      this.$loginActionsElement.hide()
      this.$registerActionsElement.show()
      this.$passwordField.prop("disabled", true)
    }
  }

  userHasPassword() {
    var value = this.$formElement.find("input:radio[name='do_you_have_a_password']:checked").prop("value")
    return (value == "true")
  }
}

export default LoginForm
