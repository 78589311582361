import Env from "./env"
import Log from "./log"
import Sentry from "raven-js"

const sentryUrl = "https://5ded2e28ca534647a58c02ccb6f6b8de@sentry.io/1513661"

class ExceptionNotifier {
  static start() {
    let app = new ExceptionNotifier()
    return app
  }

  constructor() {
    this.initForServerEnvs()
  }

  initForServerEnvs() {
    if(Env.isServer) {
      Sentry.config(sentryUrl, {
        environment: Env.current
      }).install()
    } else {
      Log.toConsole(`Skipping Sentry for ${Env.env}`)
    }
  }
}

export default ExceptionNotifier
