class AzureFileUpload {
  static start() {
    $(document).ajaxSuccess(() => {
      this.startImmediate()
    })

    return this
  }

  static startImmediate() {
    // Creates AzureFileUpload javascript object if element exists
    $("[data-wp-azure-file-upload-handler='true']").each(function() {
      if($(this).attr("data-wp-azure-file-upload-handler-already-initialized")) {
        return
      }

      $(this).attr("data-wp-azure-file-upload-handler-already-initialized", true)
      new AzureFileUpload($(this))
    })

    return this
  }

  constructor(element) {
    this.reset()
    this._maxFileSize = 1024 * 256
    this._allowedToUpload = false
    this._globalLoadingShowCount = 0
    this._element = element
    this.errorMessage = null

    let self = this

    this.fileElement().on("change", function(e) {
      self.onFileChosen(e)
    })

    this.deleteFileElement().on("click", function() {
      self.triggerCloudBlobUploadSessionDeleteFile(function() {})
      return false
    })

    if(this.element().data("cloud-blob-can-be-deleted") == true) {
      this.deleteFileElement().show()
    } else {
      this.deleteFileElement().hide()
    }

    return this
  }

  reset() {
    this._uploadingFile = false
    this._filePtr = 0
    this._bytesLeftToUpload = 0
    this._nextBlockSize = 0
    this._blockNumberOn = 0
    this._blockIds = []
    if(this.element()) {
      this.fileElement().val("")
    }
  }

  element() {
    return this._element
  }

  blockSize() {
    return 512 * 1024
  }

  isUploadingFile() {
    return this._uploadingFile
  }

  fileElement() {
    if(this._fileElement) { return this._fileElement }
    this._fileElement = this.element().find("input[type='file']").first()
    return this._fileElement
  }

  deleteFileElement() {
    if(this._deleteFileElement) { return this._deleteFileElement }
    this._deleteFileElement = this.element().find("[data-delete-file='true']").first()
    return this._deleteFileElement
  }

  fileSizeElement() {
    if(this._fileSizeElement) { return this._fileSizeElement }
    this._fileSizeElement = this.element().find("[data-file-size-field]").first()
    return this._fileSizeElement
  }

  fileNameElement() {
    if(this._fileNameElement) { return this._fileNameElement }
    this._fileNameElement = this.element().find("[data-filename-field]").first()
    return this._fileNameElement
  }

  maxFileSizeElement() {
    if(this._maxFileSizeElement) { return this._maxFileSizeElement }
    this._maxFileSizeElement = this.element().find("[data-max-file-size-field]").first()
    return this._maxFileSizeElement
  }

  fileTypeElement() {
    if(this._fileTypeElement) { return this._fileTypeElement }
    this._fileTypeElement = this.element().find("[data-file-type-field]").first()
    return this._fileTypeElement
  }

  parseErrorReasonFromServer(msg) {
    return JSON.parse(msg).reason
  }

  onFileChosen(e) {
    let self = this
    let files = e.target.files
    if(files.length > 0) {
      this._file = files[0]
      this._fileSize = this.file().size
      this._fileName = this.file().name
      this._fileType = this.file().type
      if(!this.isFileTypeAllowed(this._fileType)){
        this.notAllowedToUpload("fileType")
      } else {
        this.updateProgress()
        this.resetErrorMessages()
        this.triggerCloudBlobUploadSessionStart(function(success) {
          if(success) {
            self.beginUploading()
          } else {
            self.notAllowedToUpload(self.parseErrorReasonFromServer(self.errorMessage))
          }
        })
      }
    }
  }

  isFileTypeAllowed(fileType) {
    let allowedFileTypesArray = this.element().data("allowed-file-types")
    if(allowedFileTypesArray) {
      return allowedFileTypesArray.includes(fileType)
    } else {
      return true
    }
  }

  file() {
    return this._file
  }

  fileSize() {
    return this._fileSize || 0
  }

  maxFileSize() {
    return this._maxFileSize
  }

  fileName() {
    return this._fileName || ""
  }

  fileType() {
    return this._fileType || ""
  }

  timeoutInSeconds() {
    return 30
  }

  numberOfBlocks() {
    if(this.fileSize() == 0) {
      return 0
    }
    if(this.fileSize() < this.blockSize()) {
      return 1
    }
    return parseInt(this.fileSize() / this.blockSize(), 10) + 1
  }


  updateFileInformation() {
    if(this.fileName() != "") {
      this.fileNameElement().text(this.fileName())
    }
    if(this.fileSize() != 0) {
      this.fileSizeElement().text(this.fileSize())
    }
    if(this.fileType() != "") {
      this.fileTypeElement().text(this.fileType())
    }
    if(this.maxFileSize() != 0) {
      this.maxFileSizeElement().text(this.maxFileSize())
    }
  }

  uploadUrl() {
    return this._uploadUrl || null
  }

  fileReader() {
    let self = this
    if(this._fileReader) { return this._fileReader }
    this._fileReader = new FileReader()
    this._fileReader.onloadend = function(e) {
      if(e.target.readyState == FileReader.DONE) {
        let data = new Uint8Array(e.target.result)
        self.beginUploadingNextBlockOfData(data)
      }
    }
    return this._fileReader
  }

  beginUploadingNextBlockOfData(data) {
    let self = this
    let blockId = this.generateBlockId(this._blockNumberOn++)
    let fullUploadUrl = this.uploadUrl() + "&comp=block&timeout=" + this.timeoutInSeconds() + "&blockId=" + this.base64EncodedBlockId(blockId)
    this._blockIds.push(blockId)
    $.ajax({
      url: fullUploadUrl,
      type: "PUT",
      data: data,
      processData: false,
      timeout: this.timeoutInSeconds() * 1000,
      beforeSend: function(xhr) {
        xhr.setRequestHeader("x-ms-blob-type", "BlockBlob")
      }
    }).done(function() {
      self.didUploadBlockOfData(data)
    }).fail(function() {
      self.didFailUploading()
    })
  }

  beginProcessingNextBlockOfData() {
    let data = this.file().slice(this._filePtr, this._filePtr + this.blockSize())
    this._nextBlockSize = data.size
    if(this._nextBlockSize <= 0) {
      this.didFinishUploadingBlocks()
      return
    }
    data = this.fileReader().readAsArrayBuffer(data)
  }

  beginUploading() {
    if(this.isUploadingFile()) { return }
    this.reset()
    this.updateProgress()
    this._uploadingFile = true
    this._bytesLeftToUpload = this.fileSize()
    if(this.fileSize() > this.maxFileSize()) {
      this.notAllowedToUpload("maxSize")
      return
    }
    this.beginProcessingNextBlockOfData()
  }

  didUploadBlockOfData() {
    this._filePtr += this._nextBlockSize
    this.updateProgress()

    if(this._filePtr >= this.fileSize()) {
      this.didFinishUploadingBlocks()
      return
    }
    this.beginProcessingNextBlockOfData()
  }

  didFinishUploadingBlocks() {
    this.beginConfirmingBlockIds()
  }

  generateBlockId(number) {
    let blockId
    let blockIdNumber = 100000000 + number
    blockId = "BLOCK-ID-" + blockIdNumber
    return blockId
  }

  base64EncodedBlockId(blockId) {
    return btoa(blockId)
  }

  beginConfirmingBlockIds() {
    let self = this
    let url = this.uploadUrl() + "&comp=blocklist&timeout=" + this.timeoutInSeconds()
    let body = "<?xml version=\"1.0\" encoding=\"utf-8\"?><BlockList>"
    for(let index = 0; index < this._blockIds.length; index++) {
      let blockId = this._blockIds[index]
      body += "<Latest>" + this.base64EncodedBlockId(blockId) + "</Latest>"
    }
    body += "</BlockList>"
    $.ajax({
      url: url,
      type: "PUT",
      data: body,
      timeout: this.timeoutInSeconds() * 1000,
      beforeSend: function(xhr) {
        xhr.setRequestHeader("x-ms-blob-content-type", self.fileType())
      }
    }).done(function() {
      self.didFinishCloudUpload()
    }).fail(function() {
      self.didFailUploading()
    })
  }

  didFinishCloudUpload() {
    let self = this
    this.triggerCloudBlobUploadSessionEnd(function(success) {
      if(success) {
        self.didFinishUploading()
      } else {
        self.didFailUploading()
      }
    })
  }

  didFinishUploading() {
    this.reset()
  }

  didFailUploading() {
    this.reset()
    $("div#cloud-file-upload-error-message").show()
  }

  notAllowedToUpload(reason) {
    this.reset()
    switch(reason){
    case "fileType":
      $("div#cloud-file-upload-error-message-for-file-type").show()
      $("div#cloud-file-upload-error-message-for-max-size").hide()
      $("div#cloud-file-upload-error-message").hide()
      break
    case "maxSize":
      $("div#cloud-file-upload-error-message-for-max-size").show()
      $("div#cloud-file-upload-error-message-for-file-type").hide()
      $("div#cloud-file-upload-error-message").hide()
      break
    default:
      $("div#cloud-file-upload-error-message").show()
      break
    }
  }

  resetErrorMessages() {
    $("div#cloud-file-upload-error-message-for-file-type").hide()
    $("div#cloud-file-upload-error-message-for-max-size").hide()
    $("div#cloud-file-upload-error-message").hide()
  }

  currentProgress() {
    try {
      let progress = parseFloat(this._filePtr) / parseFloat(this._fileSize)
      if(progress > 1.0) { progress = 1.0 }
      if(!isFinite(progress)) { return 0.0 }
      return progress
    } catch(err) {
      // do nothing
    }
    return 0.0
  }

  updateProgress() {
    let progressElement = this.element().find("[data-progress-bar-progress]").first()
    progressElement.css({ width: this.currentProgress() * 100 + "%" })
  }

  triggerCloudBlobUploadSessionStart(callbackFunction) {
    let url = this.cloudBlobUploadSessionStartUrl()
    let self = this

    this.setAllowedToUpload(false)
    $.ajax({
      url: url,
      type: "POST",
      dataType: "json",
      data: { filename: this.fileName(), size: this.fileSize(), content_type: this.fileType() },
      timeout: this.timeoutInSeconds() * 1000,
    }).done(function(data) {
      self.setCloudBlobUploadSessionData(data)
      if(callbackFunction != undefined) {
        callbackFunction(true)
      }
    }).fail(function(response) {
      if(response.responseText) {
        self.errorMessage = response.responseText
      }
      if(callbackFunction != undefined) {
        callbackFunction(false)
      }
    })
  }

  triggerCloudBlobUploadSessionEnd(callbackFunction) {
    let url = this.cloudBlobUploadSessionEndUrl()
    let self = this
    this.setAllowedToUpload(false)
    $.ajax({
      url: url,
      type: "POST",
      dataType: "json",
      data: { filename: this.fileName(), size: this.fileSize(), content_type: this.fileType() },
      timeout: this.timeoutInSeconds() * 1000,
    }).done(function(data) {
      self.setCloudBlobUploadSessionData(data)
      if(callbackFunction != undefined) {
        callbackFunction(true)
      }
    }).fail(function() {
      if(callbackFunction != undefined) {
        callbackFunction(false)
      }
    })
  }

  triggerCloudBlobUploadSessionDeleteFile(callbackFunction) {
    let url = this.cloudBlobUploadSessionDeleteFileUrl()
    let self = this
    $.ajax({
      url: url,
      type: "DELETE",
      dataType: "json",
      data: {},
      timeout: this.timeoutInSeconds() * 1000,
    }).done(function(data) {
      self.reset()
      self.updateProgress()
      self.setCloudBlobUploadSessionData(data)
      if(callbackFunction != undefined) {
        callbackFunction(true)
      }
    }).fail(function() {
      if(callbackFunction != undefined) {
        callbackFunction(false)
      }
    })
  }

  cloudBlobUploadSessionStartUrl() {
    if(this._cloudBlobUploadSessionStartUrl) { return this._cloudBlobUploadSessionStartUrl }
    this._cloudBlobUploadSessionStartUrl = this.element().data("cloud-blob-upload-session-start-url")
    return this._cloudBlobUploadSessionStartUrl || null
  }

  cloudBlobUploadSessionEndUrl() {
    if(this._cloudBlobUploadSessionEndUrl) { return this._cloudBlobUploadSessionEndUrl }
    this._cloudBlobUploadSessionEndUrl = this.element().data("cloud-blob-upload-session-end-url")
    return this._cloudBlobUploadSessionEndUrl || null
  }

  cloudBlobUploadSessionDeleteFileUrl() {
    if(this._cloudBlobUploadSessionDeleteFileUrl) { return this._cloudBlobUploadSessionDeleteFileUrl }
    this._cloudBlobUploadSessionDeleteFileUrl = this.element().data("cloud-blob-upload-session-delete-file-url")
    return this._cloudBlobUploadSessionDeleteFileUrl || null
  }

  setCloudBlobUploadSessionData(data) {
    try {
      this._cloudBlobUploadSessionData = data
      this._uploadUrl = data.upload_url
      this._maxFileSize = parseInt(data.max_file_size)
      this.setAllowedToUpload(true)
      this.fileNameElement().text(data.filename_display)
      this.fileSizeElement().text(data.file_size_display)
      this.maxFileSizeElement().text(data.max_file_size_display)
      this.fileTypeElement().text(data.file_type_display)

      if(data.can_be_deleted == true) {
        this.deleteFileElement().show()
      } else {
        this.deleteFileElement().hide()
      }
    } catch(err) {
      this.setAllowedToUpload(false)
    }
  }

  setAllowedToUpload(flag) {
    this._allowedToUpload = flag
  }

  isAllowedToUpload() {
    return this._allowedToUpload == true
  }
}

export default AzureFileUpload
