import KeyCode from "keycode-js"
import "jquery-caret-plugin/dist/jquery.caret"

class DataTable {
  static start() {
    let self = this
    $(document).on("turbolinks:load", function() {
      self.startImmediate()
    })

    $(document).on("click", ".data-table a[data-toggle-filter=true]", function(e) {
      e.preventDefault()
      let dataTable = new DataTable($(this))
      if(dataTable.isValid()) {
        dataTable.toggleFilter(true)
      }
      return false
    })

    $(document).on("click", ".data-table a[data-download-csv=true]", function(e) {
      e.preventDefault()
      let dataTable = new DataTable($(this))
      if(dataTable.isValid()) {
        dataTable.downloadCSV(true)
      }
      return false
    })

    $(document).on("click", ".data-table a[data-cancel-filter=true]", function(e) {
      e.preventDefault()
      let dataTable = new DataTable($(this))
      if(dataTable.isValid()) {
        dataTable.hideFilter(true)
      }
      return false
    })

    $(document).on("click", ".data-table a[data-reload=true]", function(e) {
      e.preventDefault()
      let dataTable = new DataTable($(this))
      if(dataTable.isValid()) {
        dataTable.reload()
      }
      return false
    })

    $(document).on("click", ".data-table a[data-add-to-general-ledger=true]", function(e) {
      e.preventDefault()
      let dataTable = new DataTable($(this))
      let url = $(this).data("url")
      let newLedgerName = $("#add_to_new_general_ledger").val() || ""
      if(dataTable.isValid() && newLedgerName.length > 0) {
        dataTable.addToGeneralLedger(true, url, newLedgerName)
      }
      return false
    })

    $(document).on("click", ".data-table a[data-reset-filter=true]", function(e) {
      e.preventDefault()
      let dataTable = new DataTable($(this))
      if(dataTable.isValid()) {
        dataTable.resetFilter(true)
      }
      return false
    })

    $(document).on("submit", ".data-table form", function(e) {
      e.preventDefault()
      let dataTable = new DataTable($(this))
      if(dataTable.isValid()) {
        dataTable.applyFilter(true)
      }
      return false
    })

    $(document).on("click", ".data-table .pagination a", function(e) {
      e.preventDefault()
      let dataTable = new DataTable($(this))
      let pageNumber = $(this).data("page-number")
      if(dataTable.isValid() && pageNumber !== undefined) {
        dataTable.setPageNumber(pageNumber)
        dataTable.scrollToTop()
        dataTable.reload()
      }
      return false
    })

    $(document).on("change", ".data-table form select[name='sort']", function(e) {
      e.preventDefault()
      let dataTable = new DataTable($(this))
      if(dataTable.isValid()) {
        dataTable.reload()
      }
      return false
    })

    $(document).on("click", ".data-table th", function(e) {
      e.preventDefault()
      let dataTable = new DataTable($(this))
      let nextSortIdentifier = $(this).data("next-sort-identifier")
      if(nextSortIdentifier !== "" && nextSortIdentifier !== null && nextSortIdentifier !== undefined) {
        let sortElement = dataTable.sortElement()
        if(sortElement !== undefined) {
          sortElement.val(nextSortIdentifier)
          dataTable.reload()
        }
      }
      return false
    })

    $(document).on("click", ".data-table a[data-remove-current-filter=true]", function(e) {
      e.preventDefault()
      let dataTable = new DataTable($(this))
      let identifier = $(this).data("filter-identifier")
      if(dataTable.isValid()) {
        dataTable.removeCurrentFilter(identifier)
      }
      return false
    })

    $(document).on("change", ".data-table .filter select", function(e) {
      e.preventDefault()
      let dataTable = new DataTable($(this))
      let elementName = $(this).attr("name")
      if(dataTable.isValid()) {
        dataTable.reload(function(newElement) {
          let element = newElement.find(`[name='${elementName}']`)
          if(element.length === 1) {
            element.trigger("focus")
          }
        })
      }
      return false
    })

    $(document).on("keydown", ".data-table .filter input[type='text']", function(e) {
      let dataTable = new DataTable($(this))
      let elementName = $(this).attr("name")
      if(dataTable.isValid()) {
        let ctrlOrCmdPressed = e.ctrlKey || e.metaKey
        let isPasting = (ctrlOrCmdPressed && e.keyCode === KeyCode.KEY_V)
        let characterPressed = (e.keyCode >= KeyCode.KEY_0)

        if(e.keyCode === KeyCode.KEY_RETURN) {
          dataTable.stopFilterChangeTimer()
          dataTable.onFilterChangeTimer()
          e.preventDefault()
          return false
        } else if(e.keyCode === KeyCode.KEY_ESCAPE) {
          dataTable.stopFilterChangeTimer()
          let identifier = elementName.replace("filter[", "").replace("]", "")
          if($(this).val() !== "") {
            dataTable.removeCurrentFilter(identifier)
          }
        } else if(e.keyCode === KeyCode.KEY_BACK_SPACE || e.keyCode === KeyCode.KEY_DELETE) {
          dataTable.startFilterChangeTimer()
        } else if(isPasting) {
          dataTable.startFilterChangeTimer()
        } else if(characterPressed && !ctrlOrCmdPressed) {
          dataTable.startFilterChangeTimer()
        }
      }
    })

    $(document).on("keydown", ".data-table .filter select", function(e) {
      let dataTable = new DataTable($(this))
      let elementName = $(this).attr("name")
      if(dataTable.isValid()) {
        if(e.keyCode === KeyCode.KEY_ESCAPE) {
          dataTable.stopFilterChangeTimer()
          let identifier = elementName.replace("filter[", "").replace("]", "")
          if($(this).val() !== "") {
            dataTable.removeCurrentFilter(identifier)
          }
        }
      }
    })

    $(document).on("focus", ".data-table .filter input[type='text']", function() {
      let dataTable = new DataTable($(this))
      let elementName = $(this).attr("name")
      if(dataTable.isValid()) {
        dataTable.currentFilterElementName = elementName
      }
    })

    $(document).on("blur", ".data-table .filter input[type='text']", function() {
      let dataTable = new DataTable($(this))
      if(dataTable.isValid()) {
        dataTable.currentFilterElementName = null
      }
    })

    return this
  }

  static startImmediate() {
    $(".data-table").each(function() {
      new DataTable($(this))
    })

    return this
  }

  constructor(element) {
    this.domId = null
    this.url = null
    this.$form = null
    this.duration = 333
    this.filterChangeTimer = null
    this.currentFilterElementName = ""
    this.setPageNumber(1)
    this.setupFromElement(element)
  }

  isValid() {
    return (this.$element.length > 0 && this.$element.hasClass("data-table"))
  }

  startFilterChangeTimer() {
    this.stopFilterChangeTimer()
    let self = this
    let timer = setTimeout(function() {
      self.onFilterChangeTimer()
    }, 1000)
    this.filterChangeTimer = timer
  }

  stopFilterChangeTimer() {
    if(this.filterChangeTimer !== null) {
      clearTimeout(this.filterChangeTimer)
      this.filterChangeTimer = null
    }
  }

  onFilterChangeTimer() {
    this.stopFilterChangeTimer()
    let self = this

    let elementName = self.currentFilterElementName() || ""
    let $element = this.$element.find(`[name='${elementName}']`)
    let caretPosition = -1

    if($element.length === 1) {
      caretPosition = $element.caret()
    }

    this.reload(function(newElement) {
      let elementName = self.currentFilterElementName() || ""
      let $element = newElement.find(`input[name='${elementName}']`)
      if($element.length === 1) {
        if(caretPosition === -1) {
          $element.trigger("focus")
        } else {
          $element.trigger("focus")
          $element.caret(caretPosition)
        }
      }
      self.currentFilterElementName = ""
    })
  }

  setupFromElement(element) {
    let dataTableElement = element.closest(".data-table")
    dataTableElement.data("wp-auto-init-done", true)
    dataTableElement.data("wp-data-table", this)
    this.$element = dataTableElement
    this.domId = this.$element.attr("id")
    this.url = this.$element.find("input[name='data_table[url]']").first().val()
    this.$form = this.$element.find("form").first()
  }

  setPageNumber(pageNumber) {
    this.pageNumber = pageNumber
    if(this.$element !== undefined) {
      this.pageElement().val(pageNumber)
    }
  }

  scrollToTop() {
    $("html, body").animate({
      scrollTop: $(`#${this.domId}`).offset().top
    }, 250)
  }

  reload(afterReload) {
    let data = this.$form.serialize()
    let self = this

    this.stopFilterChangeTimer()
    if(this.url === undefined) {
      return
    }

    this.hideFilter(function() {
      $.ajax({
        method: "POST",
        url: self.url,
        data: data,
        dataType: "script"
      }).done(function() {
        let newElement = $(`#${self.domId}`).first()
        self.setupFromElement(newElement)

        if(typeof afterReload === "function") {
          afterReload(newElement)
        }
      })
    })
  }

  tableElement() {
    return this.$element.find(".data-table-table").first()
  }

  filterElement() {
    return this.$element.find(".filter-and-sort-filters").first()
  }

  sortElement() {
    return this.$element.find("[name='sort']").first()
  }

  pageElement() {
    return this.$element.find("input[name='data_table[page]']").first()
  }

  toggleFilter(callbackFunction) {
    if(!this.filterElement().is(":visible")) {
      this.showFilter(callbackFunction)
    } else {
      this.hideFilter(callbackFunction)
    }
  }

  showFilter(callbackFunction) {
    if(this.filterElement().length === 0) {
      if(callbackFunction !== undefined) {
        callbackFunction()
      }
      return
    }
    if(this.tableElement().length !== 0) {
      this.tableElement().css({
        opacity: 0.1
      })
    }
    this.filterElement().show()
    if(callbackFunction !== undefined) {
      callbackFunction()
    }
  }

  hideFilter(callbackFunction) {
    if(this.filterElement().length === 0) {
      if(callbackFunction !== undefined) {
        callbackFunction()
      }
      return
    }
    this.filterElement().hide()
    if(this.tableElement().length !== 0) {
      this.tableElement().css({
        opacity: 1.0
      })
    }
    if(callbackFunction !== undefined) {
      callbackFunction()
    }
  }

  applyFilter() {
    this.setPageNumber(1)
    this.reload()
  }

  resetFilter() {
    this.$element.find("[data-reset-value]").each(function() {
      let value = $(this).data("reset-value")
      $(this).val(value)
    })
    this.setPageNumber(1)
    this.reload()
  }

  removeCurrentFilter(identifier) {
    let $element = this.$form.find(`[name='filter[${identifier}]']`)
    let self = this
    $element.val("")
    this.setPageNumber(0)
    this.reload(function() {
      $element = self.form.find(`[name='filter[${identifier}]']`)
      if($element.length === 1) {
        $element.trigger("focus")
      }
    })
  }

  showActionsFromEvent(e) {
    let $element = $(e.target)
    let row = $element.closest("tr")
    let button = row.find("td.column-actions button").first()
    if(button.length === 1) {
      let actionsControl = button.data("wp-control")
      actionsControl.setX(e.pageX)
      actionsControl.setY(e.pageY)
      actionsControl.show()
    }
  }

  clickDefaultAction(e) {
    let $element = $(e.target)
    let row = $element.closest("tr")
    let button = row.find("td.column-actions button").first()
    if(button.length === 1) {
      let actionsControl = button.data("wp-control")
      actionsControl.clickDefaultAction(e)
      row.addClass("clicked")
    }
  }

  downloadCSV() {
    let data = this.$form.serialize()

    if(this.url === undefined) {
      return
    }

    this.hideFilter(() => {
      let parts = this.url.split(/\?/)
      let csvUrl = `${parts[0]}.csv?${parts[1]}&${data}`
      location.href = csvUrl
    })
  }

  addToGeneralLedger(batchUrl, newLedgerName) {
    let data = this.$form.serializeArray()
    data.push({ name: "new_general_ledger_batch_name", value: newLedgerName })
    let self = this

    if(batchUrl === undefined) {
      return
    }
    this.hideFilter(function() {
      $.ajax({
        method: "POST",
        url: batchUrl,
        data: data,
        dataType: "script"
      }).done(function() {
        let newElement = $(`#${self.domId}`).first()
        self.setupFromElement(newElement)
      })
    })
  }
}

export default DataTable
