import Env from "./env"

class Log {
  static toConsole(msg) {
    if(Env.notProduction) {
      console.log(msg) // eslint-disable-line no-console
    }
  }
}

export default Log
