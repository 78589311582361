class Env {
  static get isProduction() {
    return this.env === "production"
  }

  static get notProduction() {
    return !this.isProduction
  }

  static get isTest() {
    return this.env === "test"
  }

  static get isServer() {
    return this.env !== "development" && this.env !== "test"
  }

  static get env() {
    let currentEnv = $("html").data("environment")

    if(currentEnv === undefined) {
      throw "No data-environment defined!"
    }

    return currentEnv
  }
}

export default Env
