import Log from "../shared/log"
import Turbolinks from "turbolinks"

class Downloads {
  static start() {
    let app = new Downloads()
    return app
  }

  static showProgressBar() {
    if(!Turbolinks.supported) {
      return
    }
    Turbolinks.controller.adapter.progressBar.setValue(0)
    Turbolinks.controller.adapter.progressBar.show()
  }

  static hideProgressBar() {
    if(!Turbolinks.supported) {
      return
    }
    Turbolinks.controller.adapter.progressBar.hide()
    Turbolinks.controller.adapter.progressBar.setValue(100)
  }

  constructor() {
    this.setupEvents()
    Log.toConsole("Starting Download Manager")
  }

  setupEvents() {
    $(document).on("click", ".download-selections li", (e) => {
      let li = $(e.target)
      li.siblings().attr("data-selected", "false")
      li.attr("data-selected", "true")

      let url = $("#download-results").data("url")
      if(url !== undefined) {
        Downloads.showProgressBar()
        $.ajax({
          method: "POST",
          url: url,
          data: {
            version: this.getFilterValue($("#download-filter-version")),
            os: this.getFilterValue($("#download-filter-os")),
            release_type: this.getFilterValue($("#download-filter-release-type")),
            bit: this.getFilterValue($("#download-filter-bit"))
          }
        }).always(function() {
          Downloads.hideProgressBar()
        })
      }
    })
  }

  getFilterValue(li) {
    return $(li).children("li[data-selected='true']").data("value")
  }
}

export default Downloads
