import "./app"

import AddressLookup from "./lib/my_account/address_lookup"
import DataTable from "./lib/my_account/data_table"
import Downloads from "./lib/my_account/downloads"
import LoginForm from "./lib/my_account/login_form"
import PasswordChecker from "./lib/my_account/password_checker"
import ScrollTo from "./lib/my_account/scroll_to"
import VideoSearch from "./lib/my_account/video_search"

AddressLookup.start()
DataTable.start()
Downloads.start()
LoginForm.start()
PasswordChecker.start()
ScrollTo.start()
VideoSearch.start()

window.App.AddressLookup = AddressLookup
