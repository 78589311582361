class ChooseCountryPrompt {
  static start() {
    $(document).on("click", "#choose-country-or-region-prompt-close-link", function() {
      $("#choose-country-or-region-prompt").slideUp(200)
      $("a#choose-country-or-region-options").remove()
    })
  }
}

export default ChooseCountryPrompt
