const ADDRESS_LOOKUP_HANDLER_MODE_MUST_FIND_ADDRESS = 1
const ADDRESS_LOOKUP_HANDLER_MODE_FINDING_ADDRESS = 2
const ADDRESS_LOOKUP_HANDLER_MODE_ENTER_MANUAL_ADDRESS = 3
const ADDRESS_LOOKUP_HANDLER_MODE_FOUND_ADDRESS = 4

export class AddressLookupAddressData {
  constructor(data) {
    this.address_1 = (data.address_1 || "").toString().trim()
    this.address_2 = (data.address_2 || "").toString().trim()
    this.address_3 = (data.address_3 || "").toString().trim()
    this.city = (data.city || "").toString().trim()
    this.county = (data.county || "").toString().trim()
    this.postcode = (data.postcode || "").toString().trim()
    this.summary = (data.summary || "No Summary").toString().trim()
  }
}

class AddressLookup {
  static start() {
    $(document).on("turbolinks:load", () => {
      this.startImmediate()
    })

    $(document).ajaxSuccess(() => {
      this.startImmediate()
    })

    return this
  }

  static startImmediate() {
    // Creates AddressLookup javascript object if element exists
    $("[data-wp-address-lookup-handler='true']").each(function() {
      if($(this).attr("data-wp-address-lookup-handler-already-initialized")) {
        return
      }

      $(this).attr("data-wp-address-lookup-handler-already-initialized", true)
      new AddressLookup($(this))
    })

    return this
  }

  constructor(handlerElement) {
    let self = this
    this._mode = ADDRESS_LOOKUP_HANDLER_MODE_MUST_FIND_ADDRESS
    this._handlerElement = handlerElement
    this._addressLookupHtml = this._handlerElement.attr("data-address-lookup-html")
    this._addressLookupIdentifier = this._handlerElement.attr("data-address-lookup-identifier")
    this._useAdminLayout = this._handlerElement.data("use-admin-layout")

    this._element = $("<div style='display: none;'>" + this._addressLookupHtml + "</div>")
    this.parentForm().parent().append(this._element)

    this.setCurrentAddress(new AddressLookupAddressData({}))
    this.reset()

    this._element.on("click", "[data-manually-enter-address-link='true']", function() {
      self.manuallyEnterAddress()
      return false
    })

    this._element.on("click", "[data-find-address-link='true']", function() {
      self.findAddress()
      return false
    })

    this.findAddressElement().on("click", function() {
      self.findAddress()
      return false
    })

    this.manuallyAlterAddressElement().on("click", function() {
      self.manuallyEnterAddress()
      return false
    })

    this.cancelElement().on("click", function() {
      self.cancel()
      return false
    })

    this.useAddressElement().on("click", function() {
      self.useAddress()
      return false
    })

    this.nextPageElements().on("click", function() {
      self.gotoNextPage()
      return false
    })

    this.previousPageElements().on("click", function() {
      self.gotoPreviousPage()
      return false
    })

    this._element.on("click", "[data-choose-address-link='true']", function() {
      let index = parseInt($(this).data("index"))
      self.chooseAddressAtIndex(index)
      return false
    })

    this.fieldElementForFieldName("country_id").on("change", function() {
      self.reset()
      return false
    })

    this.parentFormActionElementForName("begin_lookup").on("click", function() {
      self.show()
      return false
    })

    this.fieldElementForFieldName("query").on("keypress", function(e) {
      if(e.keyCode == 13) {
        self.findAddress()
        return false
      }
    })

    return this
  }

  parentForm() {
    return this._handlerElement.closest("form")
  }

  hideFormErrors(form) {
    form.find(".errors").hide()
    return this
  }

  parentFormFieldElementForFieldName(fieldName) {
    return this.parentForm().find("[data-address-lookup-identifier='" + this._addressLookupIdentifier + "'][data-address-field='" + fieldName + "']").first()
  }

  parentFormAddressDisplayElement() {
    return this.parentForm().find("[data-address-lookup-identifier='" + this._addressLookupIdentifier + "'][data-address-display='true']").first()
  }

  parentFormActionElementForName(actionName) {
    return this.parentForm().find("[data-address-lookup-identifier='" + this._addressLookupIdentifier + "'][data-address-action='" + actionName + "']").first()
  }

  reset() {
    this._numberOfAddresses = 0
    this._foundAddresses = []
    this.switchToMode(ADDRESS_LOOKUP_HANDLER_MODE_MUST_FIND_ADDRESS)
    this._previousPageUrl = null
    this._nextPageUrl = null
  }

  nextPageUrl() {
    return this._nextPageUrl
  }

  previousPageUrl() {
    return this._previousPageUrl
  }

  hasNextPageUrl() {
    return this.nextPageUrl() != null
  }

  hasPreviousPageUrl() {
    return this.previousPageUrl() != null
  }

  hideAllActions() {
    this.findAddressElement().hide()
    this.useAddressElement().hide()
    this.manuallyAlterAddressElement().hide()
    return this
  }

  paginationElements() {
    return this._element.find(".address-lookup-pagination")
  }

  hidePagination() {
    this.paginationElements().hide()
    return this
  }

  cantFindAddressElement() {
    return this._element.find(".address-lookup-cant-find-address").first()
  }

  showPaginationIfNeeded() {
    this.nextPageElements().hide()
    this.previousPageElements().hide()
    if(this.hasPreviousPageUrl()) {
      this.previousPageElements().show()
      this.paginationElements().show()
    }
    if(this.hasNextPageUrl()) {
      this.nextPageElements().show()
      this.paginationElements().show()
    }
    return this
  }

  switchToMode(mode) {
    this._mode = mode
    this.removeErrors()
    this.hideAllActions()
    this.hidePagination()
    this.containerElementForFieldName("query").hide()

    if(this._mode == ADDRESS_LOOKUP_HANDLER_MODE_MUST_FIND_ADDRESS) {
      if(this.countryId() != 0 && !this.countryIdSupportsAddressFinding(this.countryId())) {
        this._mode = ADDRESS_LOOKUP_HANDLER_MODE_ENTER_MANUAL_ADDRESS
      }
    }

    switch(this._mode) {
    case ADDRESS_LOOKUP_HANDLER_MODE_MUST_FIND_ADDRESS:
      this.hideResults()
      this.hideAddress()
      this.cancelElement().show()
      if(this.countryId() != 0) {
        this.findAddressElement().show()
      }
      if(this.countryIdSupportsAddressFinding(this.countryId())) {
        this.containerElementForFieldName("query").show()
        this.fieldElementForFieldName("query").trigger("focus")
        this.fieldElementForFieldName("query").trigger("select")
      }
      break
    case ADDRESS_LOOKUP_HANDLER_MODE_FINDING_ADDRESS:
      this.showResults()
      this.hideAddress()
      this.cancelElement().show()
      this.showPaginationIfNeeded()
      break
    case ADDRESS_LOOKUP_HANDLER_MODE_FOUND_ADDRESS:
      this.hideResults()
      this.showAddress()
      this.setReadonlyFieldNames(["county", "postcode"])
      this.setReadonlyFieldNamesFromAddress(this._currentAddress)
      this.cancelElement().show()
      this.findAddressElement().show()
      this.useAddressElement().show()
      this.manuallyAlterAddressElement().show()
      break
    case ADDRESS_LOOKUP_HANDLER_MODE_ENTER_MANUAL_ADDRESS:
      this.hideResults()
      this.showAddress()
      this.setReadonlyFieldNames([])
      this.fieldElementForFieldName("address_1").trigger("focus")
      this.fieldElementForFieldName("address_1").trigger("select")
      this.useAddressElement().show()
      this.cancelElement().show()
      if(this.countryIdSupportsAddressFinding(this.countryId())) {
        this.findAddressElement().show()
      }
      break
    }
    this.scrollIntoView()
    return this
  }

  mode() {
    return this._mode
  }

  begin() {
    return this
  }

  show() {
    this.parentForm().hide()
    this._element.show()
    this.reset()
    this.setCurrentAddress(this.getParentAddress())
    this.hideFormErrors(this.parentForm())
    return this
  }

  hide() {
    this.parentForm().show()
    this._element.hide()
    try {
      this.parentFormAddressDisplayElement()[0].scrollIntoView(false)
    } catch(err) {
      // do nothing
    }
    return this
  }

  cancel() {
    this.hide()
    return this
  }

  resultsElement() {
    return this._element.find(".address-lookup-results").first()
  }

  useAddressElement() {
    return this._element.find(".address-lookup-use-address-action").first()
  }

  manuallyAlterAddressElement() {
    return this._element.find(".address-lookup-manually-alter-address-action").first()
  }

  cancelElement() {
    return this._element.find(".address-lookup-cancel-action").first()
  }

  nextPageElements() {
    return this._element.find(".address-lookup-next-page-action")
  }

  previousPageElements() {
    return this._element.find(".address-lookup-previous-page-action")
  }

  findAddressElement() {
    return this._element.find(".address-lookup-find-address-action").first()
  }

  containerElementForFieldName(fieldName) {
    return this._element.find("[name='address[" + fieldName + "]']").closest("li").first()
  }

  fieldElementForFieldName(fieldName) {
    return this._element.find("[name='address[" + fieldName + "]']").first()
  }

  setValueForFieldName(value, fieldName) {
    if(value == null) { value = "" }
    value = value.toString().trim()
    this.fieldElementForFieldName(fieldName).val(value)
    return this
  }

  valueForFieldName(fieldName) {
    let value = this.fieldElementForFieldName(fieldName).val()
    if(value == null) { value = "" }
    value = value.toString().trim()
    return value
  }

  countryName() {
    let element = this.fieldElementForFieldName("country_id")
    let value = this.valueForFieldName("country_id")
    let countryName = ""
    if(value && value != "") {
      countryName = element.find("option[value='" + value + "']").first().html()
    }
    return countryName
  }

  valueForFieldNameIsPresent(fieldName) {
    return this.valueForFieldName(fieldName) != ""
  }


  hideAddress() {
    this.containerElementForFieldName("address_1").hide()
    this.containerElementForFieldName("address_2").hide()
    this.containerElementForFieldName("address_3").hide()
    this.containerElementForFieldName("city").hide()
    this.containerElementForFieldName("county").hide()
    this.containerElementForFieldName("postcode").hide()
  }

  showAddress() {
    this.containerElementForFieldName("address_1").show()
    this.containerElementForFieldName("address_2").show()
    this.containerElementForFieldName("address_3").show()
    this.containerElementForFieldName("city").show()
    this.containerElementForFieldName("county").show()
    this.containerElementForFieldName("postcode").show()
  }

  hideResults() {
    this.resultsElement().hide()
    this.cantFindAddressElement().hide()
    return this
  }

  showResults() {
    let resultsElement = this.resultsElement()
    let entries = []
    let title

    if(this._numberOfAddresses == 0) {
      title = I18n.translate("javascript.shared.address_lookup.found.none")
    } else if(this._numberOfAddresses == 1) {
      title = I18n.translate("javascript.shared.address_lookup.found.one")
    } else {
      title = I18n.translate("javascript.shared.address_lookup.found.many", { count: this._numberOfAddresses })
    }

    if(this._useAdminLayout) {
      entries.push("<ul>")
      entries.push("<li><div class='title'>" + title + "</li>")
      for(let index = 0; index < this._foundAddresses.length; index++) {
        let address = this._foundAddresses[index]
        let entry = "<li><a href='#' data-choose-address-link=true data-index='" + index + "'>" + address.summary + "</a></li>"
        entries.push(entry)
      }
      entries.push("</ul>")
    } else {
      entries.push("<div class='list-group'>")
      entries.push("<div class='title text-center'>" + title + "</div>")
      for(let index = 0; index < this._foundAddresses.length; index++) {
        let address = this._foundAddresses[index]
        let entry = "<a class='list-group-item list-group-item-action' href='#' data-choose-address-link=true data-index='" + index + "'>" + address.summary + "</a>"
        entries.push(entry)
      }
      entries.push("</div>")
    }
    resultsElement.html(entries.join(""))
    resultsElement.show()
    this.cantFindAddressElement().show()
    return this
  }

  setReadonlyFieldNames(fieldNames) {
    let allFieldNames = ["address_1", "address_2", "address_3", "city", "county", "postcode"]
    for(let index = 0; index < allFieldNames.length; index++) {
      let fieldName = allFieldNames[index]
      let fieldElement = this.fieldElementForFieldName(fieldName)
      if(fieldNames.indexOf(fieldName) != -1) {
        fieldElement.attr("readonly", true)
      } else {
        fieldElement.attr("readonly", false)
      }
    }
    return this
  }

  setReadonlyFieldNamesFromAddress(address) {
    let readonlyFieldNames = []
    let allFieldNames = ["address_1", "address_2", "address_3", "city", "county", "postcode"]
    for(let index = 0; index < allFieldNames.length; index++) {
      let fieldName = allFieldNames[index]
      if(address[fieldName] != null && address[fieldName] != "") {
        readonlyFieldNames.push(fieldName)
      }
    }
    return this.setReadonlyFieldNames(readonlyFieldNames)
  }

  chooseAddress(address) {
    this.setCurrentAddress(address)
    this.switchToMode(ADDRESS_LOOKUP_HANDLER_MODE_FOUND_ADDRESS)
    return this
  }

  chooseAddressAtIndex(index) {
    let address = this._foundAddresses[index]
    return this.chooseAddress(address)
  }


  countryId() {
    let countryId = parseInt(this.valueForFieldName("country_id"))
    if(!isFinite(countryId)) { return 0 }
    return countryId
  }

  countryIdSupportsAddressFinding(countryId) {
    countryId = parseInt(countryId)
    if(!isFinite(countryId)) { return false }
    if(countryId == 0) { return false }
    return true
  }

  hasSelectedCountry() {
    return this.countryId() != 0
  }

  hasEnteredQuery() {
    return this.query() != ""
  }

  query() {
    return this.valueForFieldName("query")
  }

  findAddress() {
    let self = this
    if(this.mode() != ADDRESS_LOOKUP_HANDLER_MODE_MUST_FIND_ADDRESS) {
      this.switchToMode(ADDRESS_LOOKUP_HANDLER_MODE_MUST_FIND_ADDRESS)
      return this
    }
    if(!this.hasSelectedCountry() || !this.hasEnteredQuery()) {
      this.switchToMode(ADDRESS_LOOKUP_HANDLER_MODE_MUST_FIND_ADDRESS)
      if(!this.hasEnteredQuery()) {
        this.addError(I18n.translate("javascript.shared.address_lookup.errors.please_enter_a_query"))
      }
      this.scrollIntoView()
      return
    }
    this.loadAddresses(null, function(data) {
      self.loadedAddresses(data)
    })
    return this
  }

  manuallyEnterAddress() {
    this.switchToMode(ADDRESS_LOOKUP_HANDLER_MODE_ENTER_MANUAL_ADDRESS)
    return this
  }

  loadAddresses(url, callbackFunction) {
    let countryId = encodeURIComponent(this.countryId())
    let query = encodeURIComponent(this.query())
    if(url == null) {
      url = "/addresses/lookup?country_id=" + countryId + "&query=" + query
    }
    $.ajax({
      url: url,
      type: "GET",
      timeout: 30000,
      format: "json",
    }).done(function(data) {
      callbackFunction(data)
    }).fail(function() {
      callbackFunction(null)
    })
  }

  loadedAddresses(data) {
    this._foundAddresses = []
    this._numberOfAddresses = 0
    this._previousPageUrl = null
    this._nextPageUrl = null
    if(data != null) {
      this._previousPageUrl = data.previous_page_url
      this._nextPageUrl = data.next_page_url
      for(let index = 0; index < data.addresses.length; index++) {
        let apiAddress = data.addresses[index]
        let address = new AddressLookupAddressData({
          address_1: apiAddress.address_1,
          address_2: apiAddress.address_2,
          address_3: apiAddress.address_3,
          city: apiAddress.city,
          county: apiAddress.county,
          postcode: apiAddress.postcode,
          summary: apiAddress.summary
        })
        this._foundAddresses.push(address)
      }
      this._numberOfAddresses = data.total_number_of_addresses
    }
    this.switchToMode(ADDRESS_LOOKUP_HANDLER_MODE_FINDING_ADDRESS)
    this.scrollIntoView()
    return this
  }

  scrollIntoView() {
    try {
      this._element[0].scrollIntoView()
    } catch(err) {
      // do nothing
    }
    return this
  }

  useAddress() {
    if(!this.validateAddress()) {
      this.removeErrors()
      this.addError(I18n.translate("javascript.shared.address_lookup.errors.invalid_address"))
      this.scrollIntoView()
    } else {
      this.setParentAddress()
      this.hide()
    }
    return this
  }

  gotoNextPage() {
    let self = this
    if(this.hasNextPageUrl()) {
      this.loadAddresses(this.nextPageUrl(), function(data) {
        self.loadedAddresses(data)
      })
    }
    return this
  }

  gotoPreviousPage() {
    let self = this
    if(this.hasPreviousPageUrl()) {
      this.loadAddresses(this.previousPageUrl(), function(data) {
        self.loadedAddresses(data)
      })
    }
    return this
  }

  setParentAddress() {
    let address1 = this.valueForFieldName("address_1")
    let address2 = this.valueForFieldName("address_2")
    let address3 = this.valueForFieldName("address_3")
    let city = this.valueForFieldName("city")
    let county = this.valueForFieldName("county")
    let postcode = this.valueForFieldName("postcode")
    let countryId = this.valueForFieldName("country_id")
    let countryName = this.countryName()

    this.parentFormFieldElementForFieldName("address_1").val(address1)
    this.parentFormFieldElementForFieldName("address_2").val(address2)
    this.parentFormFieldElementForFieldName("address_3").val(address3)
    this.parentFormFieldElementForFieldName("city").val(city)
    this.parentFormFieldElementForFieldName("county").val(county)
    this.parentFormFieldElementForFieldName("postcode").val(postcode)
    this.parentFormFieldElementForFieldName("country_id").val(countryId)
    this.parentForm().find("#user_country_id").val(countryId)

    let addressHtml = []
    if(address1 != "") { addressHtml.push(address1) }
    if(address2 != "") { addressHtml.push(address2) }
    if(address3 != "") { addressHtml.push(address3) }
    if(city != "") { addressHtml.push(city) }
    if(county != "") { addressHtml.push(county) }
    if(postcode != "") { addressHtml.push(postcode) }
    if(countryName != "") { addressHtml.push(countryName) }
    this.parentFormAddressDisplayElement().html(addressHtml.join("<br>")).show()
  }

  getParentAddress() {
    let self = this
    let data = {
      address_1: self.parentFormFieldElementForFieldName("address_1").val(),
      address_2: self.parentFormFieldElementForFieldName("address_2").val(),
      address_3: self.parentFormFieldElementForFieldName("address_3").val(),
      city: self.parentFormFieldElementForFieldName("city").val(),
      county: self.parentFormFieldElementForFieldName("county").val(),
      postcode: self.parentFormFieldElementForFieldName("postcode").val()
    }

    return new AddressLookupAddressData(data)
  }

  validateAddress() {
    if(!this.valueForFieldNameIsPresent("address_1")) { return false }
    if(!this.valueForFieldNameIsPresent("city")) { return false }
    if(!this.valueForFieldNameIsPresent("county")) { return false }
    if(!this.valueForFieldNameIsPresent("postcode")) { return false }
    if(!this.valueForFieldNameIsPresent("country_id")) { return false }
    return true
  }

  setCurrentAddress(address) {
    this._currentAddress = address
    this.fieldElementForFieldName("address_1").val(address.address_1)
    this.fieldElementForFieldName("address_2").val(address.address_2)
    this.fieldElementForFieldName("address_3").val(address.address_3)
    this.fieldElementForFieldName("city").val(address.city)
    this.fieldElementForFieldName("county").val(address.county)
    this.fieldElementForFieldName("postcode").val(address.postcode)
    return this
  }

  errorsElement() {
    return this._element.find(".address-lookup-errors").first()
  }

  removeErrors() {
    this.errorsElement().html("")
    this.errorsElement().hide()
    return this
  }

  addError(message) {
    let liElement = $("<li>" + message + "</li>")
    let ulElement = this.errorsElement().find("ul").first()
    if(ulElement.length == 0) {
      ulElement = $("<ul></ul>")
      this.errorsElement().append(ulElement)
    }
    ulElement.append(liElement)
    this.errorsElement().show()
    return this
  }
}

export default AddressLookup
