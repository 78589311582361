class ReplaceForm {
  static start() {
    $(document)
      .on("change", "[data-wp-replace-form]", function(event) {
        event.preventDefault()
        new ReplaceForm($(this))
      })

    return this
  }

  constructor(element) {
    let replaceFormData = { actionToProcess: element.data("action-to-process") }
    let form = element.closest("form")

    if(form.length > 0) {
      this.triggerFormReplace(form, replaceFormData)
    }
  }

  triggerFormReplace(form, replaceFormData) {
    let self = this
    let method = form.attr("method")
    let url = form.attr("action")

    if(replaceFormData == undefined) {
      replaceFormData = {}
    }

    this.addHiddenFieldToForm(form, "_is_replacing_form", "true")

    let actionToProcess = replaceFormData.actionToProcess || {}
    for(let attr in actionToProcess) {
      let hiddenFieldName = "_action_to_process[" + attr + "]"
      this.addHiddenFieldToForm(form, hiddenFieldName, actionToProcess[attr])
    }

    $.ajax({
      type: method,
      url: url,
      data: form.serialize()
    }).always(function() {
      self.removeHiddenFieldFromForm(form, "_is_replacing_form")
      for(let attr in actionToProcess) {
        let hiddenFieldName = "_action_to_process_" + attr
        self.removeHiddenFieldFromForm(form, hiddenFieldName)
      }
    })
  }

  addHiddenFieldToForm(form, fieldName, value) {
    let hiddenField = form.find("input[name='" + fieldName + "']").first()
    if(hiddenField.length == 0) {
      hiddenField = $("<input type='hidden' name='" + fieldName + "' />")
      form.append(hiddenField)
    }
    hiddenField.val(value)
  }

  removeHiddenFieldFromForm(form, fieldName) {
    let hiddenField = form.find("input[name='" + fieldName + "']").first()
    if(hiddenField.length > 0) {
      hiddenField.val("")
      hiddenField.remove()
    }
  }
}

export default ReplaceForm
