class AzureMediaPlayer {
  static start() {
    $(document).on("turbolinks:load", () => {
      this.startImmediate()
    })
  }

  static startImmediate() {
    // Starts AzureMediaPlayer Javascript object
    $("[data-wp-azure-media-player-handler='true']").each(function() {
      if($(this).attr("data-wp-azure-media-player-handler-already-initialized")) {
        return
      }

      $(this).attr("data-wp-azure-media-player-handler-already-initialized", true)
      new AzureMediaPlayer($(this))
    })
  }

  constructor(element) {
    this._element = element
    this._playing = false
    this._timer = null
    this._player = null
    this._isRecordingStats = false
    this._videoSourceUrl = null
    this._videoPlaybackSessionToken = null
    this._language = null
    this._posterImageUrl = null
    this._videoPlaybackFinishedRemoteUrl = null
    this.onPlayEventListenerFunction = null
    this.onPauseEventListenerFunction = null
    this.onPlaybackFinishedEventListenerFunction = null
    this.begin()
  }

  wpMediaData() {
    return this._element.data("wp-media")
  }

  videoTagId() {
    return this.wpMediaData()["video_tag_id"]
  }

  shouldAskToResume() {
    return this.wpMediaData()["should_ask_to_resume"] === "true"
  }

  videoElement() {
    return $("#" + this.videoTagId())
  }

  player() {
    if(this._player) { return this._player }
    try {
      let myOptions = {
        "nativeControlsForTouch": false,
        "logo": { "enabled": false },
        controls: true,
        autoplay: !this.shouldAskToResume(),
        width: "100%",
        height: "auto",
        language: this.language(),
        hotKeys: {
          enableVolumeScroll: false
        }
      }
      this._player = amp(this.videoTagId(), myOptions, function() {})
      this._player.src([{
        "src": this.videoSourceUrl(),
        "type": "application/vnd.ms-sstr+xml"
      }])

      if(this.posterImageUrl() != null && this.posterImageUrl() != "") {
        this._player.poster(this.posterImageUrl())
        $(".vjs-poster-span").css("height", "auto")
      }

      if(this.shouldAskToResume()) {
        $(".vjs-big-play-button").hide()
        this._player.poster(this.posterImageUrl())
      }
      this.addObservers()
    } catch(err) {
      this._player = null
    }
    return this._player
  }

  currentTime() {
    let currentTime
    try {
      currentTime = this.player().currentTime()
    } catch(err) {
      currentTime = 0
    }
    return currentTime == null ? 0 : currentTime
  }

  currentTimeInSeconds() {
    return parseInt(this.currentTime())
  }

  duration() {
    let duration
    try {
      duration = this.player().duration()
    } catch(err) {
      duration = 0
    }
    return duration == null ? 0 : duration
  }

  durationInSeconds() {
    return parseInt(this.duration())
  }

  onPlay() {
    this.hideResume()
    this.startTimer()
  }

  onPause() {
    this.stopTimer()
  }

  onPlaybackFinished() {
    this.stopTimer()
    let url = this.videoPlaybackFinishedRemoteUrl()
    if(url != null) {
      $.ajax({
        method: "GET",
        url: url,
        dataType: "script"
      }).done(function() {})
    }
  }

  isPlaying() {
    return this._playing
  }

  startTimer() {
    let self = this
    if(this._timer == null) {
      this.stopTimer()
    }
    this._timer = setInterval(function() {
      self.onTimer()
    }, 2000)
  }

  stopTimer() {
    if(this._timer != null) {
      clearInterval(this._timer)
      this._timer = null
    }
  }

  onTimer() {
    this.recordStats()
  }

  hideResume(callbackFunction) {
    this._element.find(".resume").slideUp(250, function() {
      if(callbackFunction) {
        callbackFunction()
      }
    })
  }

  addObservers() {
    let player = this.player()
    let self = this

    if(this._element.find("a[data-resume-video-playback]").length) {
      this._element.on("click", "a[data-resume-video-playback]", function() {
        let currentTime = parseInt($(this).data("resume-video-playback"))
        self.hideResume(function() {
          self.play()
          self.seekTo(currentTime)
        })
        return false
      })
    }

    this.onPlayEventListenerFunction = function() {
      self.onPlay()
    }
    player.addEventListener("play", this.onPlayEventListenerFunction)

    this.onPauseEventListenerFunction = function() {
      self.onPause()
    }
    player.addEventListener("pause", this.onPauseEventListenerFunction)

    this.onPlaybackFinishedEventListenerFunction = function() {
      self.onPlaybackFinished()
    }
    player.addEventListener("ended", this.onPlaybackFinishedEventListenerFunction)
  }

  removeObservers() {
    let player = this.player()
    if(this.onPlayEventListenerFunction) {
      player.removeEventListener("play", this.onPlayEventListenerFunction)
    }
    if(this.onPauseEventListenerFunction) {
      player.removeEventListener("pause", this.onPauseEventListenerFunction)
    }
    if(this.onPlaybackFinishedEventListenerFunction) {
      player.removeEventListener("ended", this.onPlaybackFinishedEventListenerFunction)
    }
    this.onPlayEventListenerFunction = null
    this.onPauseEventListenerFunction = null
    this.onPlaybackFinishedEventListenerFunction = null
  }

  posterImageUrl() {
    if(this._posterImageUrl != null) { return this._posterImageUrl }
    this._posterImageUrl = this.wpMediaData()["poster_image_url"]
    return this._posterImageUrl
  }

  videoSourceUrl() {
    if(this._videoSourceUrl != null) { return this._videoSourceUrl }
    this._videoSourceUrl = this.wpMediaData()["video_source_url"]
    return this._videoSourceUrl
  }

  videoPlaybackFinishedRemoteUrl() {
    if(this._videoPlaybackFinishedRemoteUrl != null) {
      return this._videoPlaybackFinishedRemoteUrl
    }
    this._videoPlaybackFinishedRemoteUrl = this.wpMediaData()["video_playback_finished_remote_url"]
    return this._videoPlaybackFinishedRemoteUrl
  }

  language() {
    if(this._language != null) { return this._language }
    this._language = this.wpMediaData()["locale"] || "en"
    return this._language
  }

  videoPlaybackSessionToken() {
    if(this._videoPlaybackSessionToken != null) { return this._videoPlaybackSessionToken }
    this._videoPlaybackSessionToken = this.wpMediaData()["video_playback_session_token"]
    return this._videoPlaybackSessionToken
  }

  begin() {
    let self = this
    self.videoElement().attr("src", "about:blank")
    self.player()
  }

  recordStats() {
    if(this._isRecordingStats || this.videoPlaybackSessionToken() == null) {
      return
    }
    let self = this
    let url = "/stats/video-playback?"
    url += "token=" + this.videoPlaybackSessionToken()
    url += "&duration=" + this.durationInSeconds()
    url += "&time=" + this.currentTimeInSeconds()
    if(window["XMLHttpRequest"]) {
      this._isRecordingStats = true
      let xhr = new XMLHttpRequest()
      xhr.open("GET", url)
      xhr.setRequestHeader("X-Requested-With", "XMLHttpRequest")
      xhr.onreadystatechange=function() {
        if(xhr.readyState == 4) {
          self.isRecordingStats = false
        }
      }
      xhr.send()
    }
  }

  seekTo(time) {
    this.player().currentTime(time)
  }

  play() {
    this.player().play()
  }

  pause() {
    this.player().pause()
  }
}

export default AzureMediaPlayer
