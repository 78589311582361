import NProgress from "nprogress/nprogress"

class UserInformation {
  static start() {
    $(document).on("turbolinks:load", function() {
      const url = $("#user-information").data("url")

      if(url != null) {
        if(typeof NProgress !== "undefined") {
          $(document).off("ajaxStart.NProgress")
          $(document).off("ajaxStop.NProgress")
        }

        $.get(url)
          .done(function() {
            $("#user-information").fadeIn()
          })
          .always(function() {
            if(typeof NProgress !== "undefined") {
              $(document).on("ajaxStart.NProgress", function() { NProgress.start() })
              $(document).on("ajaxStop.NProgress", function() { NProgress.done() })
            }
          })
      } else {
        $("#user-information").fadeIn()
      }
    })

    return this
  }
}

export default UserInformation
