import { SHA1 } from "crypto-es/lib/sha1"
import Env from "../shared/env"

class PasswordChecker {
  static start() {
    let app
    $(document).on("turbolinks:load", () => {
      app = this.startImmediate()
    })
    return app
  }

  static startImmediate() {
    let app = new PasswordChecker("input[data-password-checker='true']:first")
    return app
  }

  constructor(passwordElementPath) {
    this.passwordElementPath = passwordElementPath

    var self = this
    $(document).on("change", this.passwordElementPath, () => {
      self.checkPassword()
    })
  }

  get $passwordField() {
    return $(this.passwordElementPath)
  }

  get $messageTag() {
    return this.$passwordField.next("[data-password-checker-message]")
  }

  get password() {
    return this.$passwordField.val()
  }

  get passwordCaseInsensitive() {
    return this.password.toLowerCase()
  }

  get passwordAsSHA1() {
    return SHA1(this.passwordCaseInsensitive).toString().toUpperCase()
  }

  get passwordPrefix() {
    return this.passwordAsSHA1.slice(0, 5)
  }

  get passwordSuffix() {
    return this.passwordAsSHA1.slice(5)
  }

  checkPassword() {
    this.$messageTag.hide()
    if(this.password.length === 0) { return }
    if(Env.isTest) { return }

    var url = `https://api.pwnedpasswords.com/range/${this.passwordPrefix}`
    var self = this

    $.get(url, (unsafePasswordSHAs) => {
      if(unsafePasswordSHAs.indexOf(self.passwordSuffix) !== -1) {
        self.$messageTag.show()
      }
    })
  }
}

export default PasswordChecker
