// Platform specific & third-party libs
import Rails from "@rails/ujs"
import Turbolinks from "turbolinks"

import "core-js/stable"
import "regenerator-runtime/runtime"
import "bootstrap/dist/js/bootstrap"
import "@fortawesome/fontawesome-free/js/all"

// Our libs
import AzureFileUpload from "../lib/shared/azure_file_upload"
import AzureMediaPlayer from "../lib/shared/azure_media_player"
import ChooseCountryPrompt from "../lib/shared/choose_country_prompt"
import ErrorHandling from "../lib/shared/error_handling"
import ExceptionNotifier from "../lib/shared/exception_notifier"
import FontAwesomeHelper from "../lib/shared/font_awesome_helper"
import ReplaceForm from "../lib/shared/replace_form"
import UserInformation from "../lib/shared/user_information"

// Ensure essential code is loaded first
ExceptionNotifier.start()
Rails.start()
Turbolinks.start()

// Disable scroll caching for turbolinks pages
if("scrollRestoration" in window.history) {
  window.history.scrollRestoration = "manual"
}

// Then everything else:
AzureFileUpload.start()
ChooseCountryPrompt.start()
ErrorHandling.start()
FontAwesomeHelper.start()
ReplaceForm.start()
UserInformation.start()
AzureMediaPlayer.start()

// Hides video modals cached by Turbolinks
// Currently a video modal cached by Turbolinks seems to lose all handlers when loaded from cache
// i.e. I cannot click away OR click close to make the modal disappear
document.addEventListener("turbolinks:before-cache", function() {
  let openModal = $("div.modal.show")

  if(openModal.length) {
    openModal.attr("style", "")
    openModal.modal("hide")
    $("body").attr("style", "")
    $("body").removeClass("modal-open")
    $("div.modal-backdrop").remove()
  }
})

// Add to global namespace where required
window.App = window.App || {}
window.App.AzureFileUpload = AzureFileUpload
window.App.ErrorHandling = ErrorHandling

export default window.App
