class FontAwesomeHelper {
  static start() {
    if(window.FontAwesome) {
      // This will watch the document for changes and ensure the svgs are updated
      // then we don't need to do i2svg to reload icons after turbolinks:load
      window.FontAwesome.dom.watch({ observeMutationsRoot: document })
    }
  }
}

export default FontAwesomeHelper
